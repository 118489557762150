body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#E5EDEA;
  overflow: hidden;
  color: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  right:0;
  padding-left: 15px;
  padding-right:15px;
}

.card-view-enter {
  transform: translate(100%);
}
.card-view-enter-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
  position:'relative';
}
.card-view-exit {
  transform: translate(0%);
}
.card-view-exit-active {
  transform: translate(-100%);
  transition: transform 300ms;
}

.card-view-back-enter {
  transform: translate(-100%);
}
.card-view-back-enter-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
  position:'relative'
}
.card-view-back-exit {
  transform: translate(0%);
}
.card-view-back-exit-active {
  transform: translate(100%);
  transition: transform 300ms;
}


.card-view-vertical-enter {
  transform: translate(0,100%);
}
.card-view-vertical-enter-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
  position:'relative';
}
.card-view-vertical-exit {
  transform: translate(0%);
}
.card-view-vertical-exit-active {
  transform: translate(0%,-100%);
  transition: transform 300ms;
}

.card-view-vertical-back-enter {
  transform: translate(0,-100%);
}
.card-view-vertical-back-enter-active {
  transform: translate(0%);
  transition: transform 300ms ease-in-out;
  position:'relative'
}
.card-view-vertical-back-exit {
  transform: translate(0%);
}
.card-view-vertical-back-exit-active {
  transform: translate(0%,100%);
  transition: transform 300ms;
}



.btn-secondary {
  color: #555;
  background-color: #ddd;
  border-color: #5bc2c2;
}

.btn-secondary:hover {
  color: #555;
  background-color: #ddd;
  border-color: #5bc2c2
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 .2rem rgba(91, 194, 194, 0.5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #555;
  background-color: #ddd;
  border-color: #5bc2c2
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #555;
  background-color: #bbb;
  border-color: #2e7c7c
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(91, 194, 194, 0.5)
}

.btn-outline-secondary {
  color: #ddd;
  background-color: transparent;
  background-image: none;
  border-color: #ddd
}

.btn-outline-secondary:hover {
  color: #222;
  background-color: #bbb;
  border-color: #ddd
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem rgba(119, 204, 204, 0.5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #ddd;
  background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #555;
  background-color: #bbb;
  border-color: #ddd
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(119, 204, 204, 0.5)
}